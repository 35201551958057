import 'regenerator-runtime/runtime';
import $ from 'jquery';
import SwiperCore, { Navigation, Pagination, Swiper } from 'swiper/core';

SwiperCore.use([Navigation, Pagination]);

$(async () => {
  const $left = $('#slider-arrow--left');
  const $right = $('#slider-arrow--right');
  const $slider = $('.slider-item');
  const $firstSlide = $slider.first();

  const swiper = new SwiperCore('.swiper-container', {
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      afterInit: () => {
        let maxHeight = Math.max.apply(null, $slider.map((i, e) => $(e).height()).get());
        $slider.height(maxHeight);
      }
    }
  });

  $(window).on('resize', e => {
    $slider.removeAttr('style');
    let maxHeight = Math.max.apply(null, $slider.map((i, e) => $(e).height()).get());
    $slider.height(maxHeight);
  });

  $right.on('click', e => {
    e.preventDefault();
    $left.show();
    $right.hide();
    $firstSlide.animate({
      width: 'toggle',
      height: 569
    });
  });
  $left.on('click', e => {
    e.preventDefault();
    $right.show();
    $left.hide();
    $firstSlide.animate({
      width: 'toggle',
      height: 569
    });
  });
});